//page-title-area
.page-title-area {
	position: relative;
	background: rgba(16, 76, 186, 0.05);

	.page-title-shape {
		position: absolute;
		z-index: 1;
		&.shape-one {
			top: 53%;
			right: 56%;
			@media (max-width: 767.98px) {
				display: none;
			}
		}
		&.shape-two {
			top: 45.3%;
			left: 147px;
		}
		&.shape-three {
			top: 6.5%;
			right: 69.5%;
			opacity: 0.5;
		}
		&.shape-four {
			right: 22.5%;
			top: 20%;
		}
		&.shape-five {
			top: 70px;
			right: 18.85%;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
			animation: float-bob infinite 15s linear;
			-webkit-animation: float-bob infinite 15s linear;
		}
		&.shape-six {
			top: 85%;
			right: 40.5%;
		}
		&.shape-seven {
			top: 90%;
			right: 80.2%;
		}

		&.shape-eight {
			left: 0;
			top: 0;
		}
		&.shape-nine {
			right: 0;
			top: 0;
		}
	}
}

.page-title-wrapper {
	position: relative;
	z-index: 2;
	& .page-title {
		color: $white;
		font-size: 46px;
		z-index: 1;
		@media #{$md} {
			font-size: 38px;
		}
		@media #{$xs} {
			font-size: 32px;
		}
	}
}

ul.breadcrumb {
	& li {
		display: inline-block;
		& a {
			color: $white;
			font-size: 16px;
			line-height: 1;
			display: inline-block;
			padding-right: 10px;
			margin-right: 10px;
			position: relative;
			z-index: 1;
			&::before {
				content: "";
				position: absolute;
				background: $white;
				width: 5px;
				height: 2px;
				right: -4px;
				top: 7px;
				z-index: 1;
			}
		}
		&.active {
			color: $theme-color;
		}
	}
}
