//progress-content
.progressbar-content {
    .chart {
        display: inline-block;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;
        width: 132px;
        height: 132px;
        .CircularProgressbar-text {
            font-weight: 600;
            font-family: $clash-semibold;
            @media #{$xs} {
                font-size: 20px;
            }
        }
        path.CircularProgressbar-trail {
            stroke-width: 2px;
        }
    }
}
