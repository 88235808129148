//widget
.widget {
    border: 1px solid rgba($white, 0.05);
    padding: 25px 25px 25px 25px;
    @media (max-width: 1199.98px) {
        padding: 25px 15px 25px 15px;
    }
}

//service-widget
.service-widget {
    li {
        a {
            color: $white;
            font-size: 24px;
            line-height: 30px;
            font-family: $clash-semibold;
            padding: 20px 30px;
            background: rgba(255, 255, 255, 0.05);
            margin-bottom: 20px;
            display: block;
            position: relative;
            z-index: 1;
            &::before {
                content: "";
                position: absolute;
                background: $white;
                height: 100%;
                width: 2px;
                left: 0;
                top: 0;
                z-index: -1;
                @include transition(0.3s);
            }
            &::after {
                content: "";
                position: absolute;
                background: $white;
                height: 100%;
                width: 2px;
                right: 0;
                top: 0;
                z-index: -1;
                @include transition(0.3s);
            }
            &:hover {
                &::before,
                &::after {
                    width: 52%;
                    background: $prime-color;
                }
            }
        }
    }
    @media (max-width: 1200px) {
        li {
            a {
                font-size: 18px;
            }
        }
    }
}

//widget-title
.widget-title {
    color: $white;
    font-size: 40px;
    @media (max-width: 1199.98px) {
        font-size: 28px;
    }
    @media #{$xs} {
        font-size: 24px;
    }
}
//widget-sub-title2
.widget-sub-title2 {
    color: $white;
    font-size: 24px;
    font-family: $clash-medium;
    font-weight: 600;
}

//widget-form
.widget-form {
    padding: 50px 35px;
    background: rgba(255, 255, 255, 0.05);
    p {
        color: $white;
    }
    input,
    textarea {
        background: 0;
        border: 1px solid rgba($white, 0.1);
        border-radius: 5px;
        color: $white;
        font-size: 16px;
        display: block;
        height: 60px;
        width: 100%;
        padding-left: 20px;
        margin-bottom: 20px;
        &:focus {
            outline: none;
            border-color: $theme-color;
        }
        &::placeholder {
            color: $white;
            font-size: 16px;
        }
        &::-moz-placeholder {
            color: $white;
            font-size: 16px;
        }
        &::-webkit-placeholder {
            color: $white;
            font-size: 16px;
        }
        &::-o-placeholder {
            color: $white;
            font-size: 16px;
        }
        &::-ms-placeholder {
            color: $white;
            font-size: 16px;
        }
    }
    textarea {
        height: 146px;
        resize: none;
        padding-top: 10px;
    }
    .widget-btn {
        color: $white;
        font-size: 16px;
        line-height: 19px;
        font-family: $clash-semibold;
        font-weight: 600;
        border: 0;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        background: $prime-color;
        width: 100%;
        padding: 21px 0 20px 0;
        &:hover {
            background: $theme-color;
        }
    }
    @media (max-width: 1200px) {
        padding: 40px 15px;
    }
    .form-input-box {
        position: relative;
        z-index: 1;
        .invalid-feedback {
            position: absolute;
            left: 3px;
            top: 97%;
            z-index: 1;
            font-size: 14px;
            font-weight: 500;
            font-family: "ClashDisplay-Medium";
        }
    }
}

.widget-categories {
    ul {
        li {
            a {
                color: #999999;
                font-size: 16px;
                padding: 15px 0;
                display: block;
                line-height: 1;
                font-weight: 700;
                &:hover {
                    color: $heding;
                    text-decoration: underline;
                }
            }
        }
    }
}

//widget-subscribe
.widget-subscribe {
    .contact-form {
        textarea {
            height: 140px;
            padding-top: 15px;
        }
        .form-btn {
            width: 100%;
        }
    }
}

//widget-file-download
.widget-file-download {
    background: rgba(255, 255, 255, 0.05);
    padding: 40px 30px 30px;
    button {
        border: 0;
        color: $white;
        background: $prime-color;
        font-size: 24px;
        font-family: $clash-semibold;
        display: block;
        width: 100%;
        padding: 30px 30px;
        text-align: start;
        i {
            float: right;
        }
        &:first-child {
            margin-bottom: 30px;
        }
        @media (max-width: 1599.98px) {
            font-size: 20px;
            padding: 40px 15px 30px;
        }
        @media #{$xs} {
            padding: 30px 15px;
        }
    }
}

//page-navigation
.page-navigation {
    .page-link {
        display: inline-block;
        color: $white;
        background: transparent;
        font-size: 16px;
        border: 2px solid rgba($white, 0.2);
        width: 40px;
        height: 40px;
        line-height: 38px;
        text-align: center;
        margin-right: 20px;
        padding: 0;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        i {
            font-size: 24px;
        }
        &:focus {
            z-index: 2;
            color: $white;
            border-color: unset;
            outline: none;
            box-shadow: none;
        }
        &:hover {
            color: $white;
            background: $theme-color;
            border-color: $theme-color;
        }
    }
}
//widget-post
.widget-post {
    .blog-post {
        display: flex;
        align-items: center;

        img {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
        }
    }
    .post-list {
        padding: 0;
        margin: 0;
        li {
            border-bottom: 1px solid rgba($white, 0.05);
            margin-bottom: 20px;
            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
    .post-content {
        margin-left: 20px;
        h6 {
            color: $white;
            font-size: 20px;
            font-family: $clash-medium;
            font-weight: 500;
            a:hover {
                color: $theme-color;
            }
        }
        span {
            color: $theme-color;
        }
        @media (max-width: 1599.98px) {
            h6 {
                font-size: 16px;
            }
        }
    }
}

//widget-search
.widget-search {
    .subscribe-form {
        position: relative;
        z-index: 1;
        input {
            color: $white;
            font-size: 16px;
            height: 60px;
            width: 100%;
            line-height: 50px;
            padding-left: 22px;
            border: 0;
            background: rgba(255, 255, 255, 0.03);
            border-left: 1px solid #ffffff;
            &::placeholder {
                color: $white;
            }
            &::-moz-placeholder {
                color: $white;
            }
            &::-webkit-placeholder {
                color: $white;
            }
            &::-o-placeholder {
                color: $white;
            }
        }
        .widget-btn {
            border: 0;
            background: $theme-color;
            width: 54px;
            height: 50px;
            line-height: 50px;
            color: $white;
            position: absolute;
            right: 5px;
            top: 5px;
            z-index: 1;
            i {
                font-size: 16px;
            }
        }
    }
}

.widget-categories-list {
    ul {
        li {
            &:last-child {
                a {
                    margin-bottom: 0;
                }
            }
            a {
                color: $white;
                width: 100%;
                display: block;
                padding: 20px 20px 19px 20px;
                margin-bottom: 10px;
                font-size: 17px;
                line-height: 21px;
                position: relative;
                background: rgba(255, 255, 255, 0.03);
                z-index: 1;
                &::before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    background: white;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    -webkit-transition: all 0.3s ease 0s;
                    -moz-transition: all 0.3s ease 0s;
                    -ms-transition: all 0.3s ease 0s;
                    -o-transition: all 0.3s ease 0s;
                    transition: all 0.3s ease 0s;
                    z-index: -1;
                }
                span {
                    color: $theme-color;
                    float: right;
                }
                &:hover {
                    &::before {
                        background: $theme-color;
                        height: 100%;
                    }
                    span {
                        color: $white;
                    }
                }
            }
        }
    }
}
.tag-list {
    a {
        color: $white;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        background: rgba($white, 0.03);
        display: inline-block;
        padding: 16px 20px;
        margin: 5px 4px;
        line-height: 1;
        @include transition(0.3s);
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            background: white;
            width: 1px;
            height: 100%;
            left: 0;
            bottom: 0;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -ms-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            z-index: -1;
        }
        &:hover {
            color: $white;
            &::before {
                width: 100%;
                background: $theme-color;
            }
        }
        @media #{$laptop} {
            padding: 16px 16px;
            margin: 5px 2px;
        }
        @media #{$xs} {
            padding: 16px 10px;
            margin: 5px 2px;
        }
    }
}
