//main-header-area
.main-header-area {
	position: relative;
	width: 100%;
	left: 0;
	z-index: 999;
	.border-tops {
		border-top: 1px solid $hr-border-color;
	}
}
.logo-area {
	& img {
		max-width: 100%;
	}
}

/* Hamburger-menu */
.hamburger-menu {
	display: inline-block;
	a {
		display: inline-block;
		width: 46px;
		height: 46px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $white;

		& i {
			color: $black;
		}
		&:hover {
			background: $theme-color;
		}
	}
}
.side-mobile-menu {
	display: none;
	@media #{$md} {
		display: block;
	}
	@media #{$xs} {
		display: block;
	}
	@media #{$sm} {
		display: block;
	}
}

.offset-sidebar {
	display: block;
	margin-top: 30px;
	@media #{$md} {
		display: none;
	}
	@media #{$xs} {
		display: none;
	}
	@media #{$sm} {
		display: none;
	}
}

//.theme-main-menu
.theme-main-menu {
	position: relative;
	z-index: 11;
	.custom-container-nav {
		max-width: 1660px;
		width: 100%;
		margin: 0 auto;
		padding-left: 22px;
		padding-right: 22px;
		@media #{$md} {
			padding: 0 0 0 15px;
		}
		@media #{$xs} {
			padding: 0 0 0 15px;
		}
	}
	//.navbar-nav
	.navbar-nav {
		& .nav-item {
			position: relative;
			z-index: 1;

			
				& .nav-link {
					margin-right: 0;
					&:before {
						display: none;
					}
				}
			
			&.active {
				color: $heding;

				& .nav-link {
					color: $heding;
				}
			}
			& .nav-link {
				color: #ffffff;
				font-family: $clash-medium;
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 500;
				line-height: 1;
				display: inline-block;
				position: relative;
				margin: 0 50px 0 0;
				padding: 30px 0 15px 0;
				@media #{$lg} {
					margin: 0 15px 0 0;
				}
				&::before {
					content: "\F282";
					font-family: "bootstrap-icons";
					font-weight: 700;
					position: absolute;
					font-size: 14px;
					right: -20px;
					top: 20px;
					z-index: 1;
					@include transition(0.3s);
					@media #{$lg} {
						right: -25%;
					}
				}
				&::after {
					display: none;
				}

				&:hover {
					color: #fdc116;
				}
				& i {
					font-size: 10px;
				}
			}
			&:hover {
				&::before {
					width: 100px;
					background-image: -moz-linear-gradient(0deg, rgb(8, 106, 216) 0%, rgb(55, 140, 255) 100%);
					background-image: -webkit-linear-gradient(0deg, rgb(8, 106, 216) 0%, rgb(55, 140, 255) 100%);
					background-image: -ms-linear-gradient(0deg, rgb(8, 106, 216) 0%, rgb(55, 140, 255) 100%);
				}
				& > .nav-link {
					color: #d6a213;
				}
			}
		}
	}

	.navbar {
		& .dropdown-menu {
			border: 0;
			position: absolute;
			z-index: 5;
			background-color: #fff;
			border-radius: 0;
			display: block;
			right: 0;
			left: 0;
			padding: 0;
			// top: 100%;
			visibility: hidden;
			transform: scaleY(0);
			opacity: 0;
			min-width: 220px;
			box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.05);
			margin: 0;
			transform-origin: 0 0;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-ms-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			& .dropdown-item {
				color: $heding;
				font-size: 17px;
				font-family: $clash-medium;
				font-weight: 500;
				padding: 9px 25px;
				display: block;
				transition: all 0.3s ease-in-out;
				&:hover,
				&.active {
					color: $white;
					background-color: transparent;
					background: $theme-color;
				}
			}
		}

		& .dropdown:hover {
			& .dropdown-menu {
				opacity: 1;
				visibility: visible;
				transform: scale(1);
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-ms-transform: scale(1);
				-o-transform: scale(1);
			}
		}
		.dropdown-submenu {
			.dropdown-toggle::after {
				display: none;
			}
			.dropdown-menu.dropdown-menu2 {
				left: 100%;
				top: 10%;
				visibility: hidden;
				transform: scaleX(0);
				.dropdown-item {
					&::after {
						display: none;
					}
				}
			}
			&:hover {
				.dropdown-menu.dropdown-menu2 {
					visibility: visible;
					transform: scaleX(1);
					-webkit-transform: scaleX(1);
					-moz-transform: scaleX(1);
					-ms-transform: scaleX(1);
					-o-transform: scaleX(1);
				}
			}
		}
		.navbar-nav {
			.nav-item {
				&.active {
					& > .nav-link {
						color: $theme-color;
						position: relative;
					}
				}
			}
		}
	}
}

//sticky-menu
.sticky-menu {
    position: fixed;
    width: 100%;
    // background: linear-gradient(to bottom, rgba(255, 255, 255), rgba(255, 255, 255),rgba(228, 227, 227, 0.8),rgba(255, 255, 255,0)); 
    background-color:#050d37;
	top: 0;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    transition: 0.4s;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
    padding-top: 10px;
    padding-bottom: 0;
    z-index: 9999;
    
    & .logo {
        transform: translateY(2px);
    }

	&:hover {
		&::before {
			width: 100px;
			background-image: -moz-linear-gradient(0deg, rgb(8, 106, 216) 0%, rgb(55, 140, 255) 100%);
			background-image: -webkit-linear-gradient(0deg, rgb(8, 106, 216) 0%, rgb(55, 140, 255) 100%);
			background-image: -ms-linear-gradient(0deg, rgb(8, 106, 216) 0%, rgb(55, 140, 255) 100%);
		}
		& > .nav-link {
			color: #fdc116 !important;
		}
	}


	

	& .hamburger-menu {
		top: 17px;

		@media #{$md} {
			top: 35px;
		}
	}
	.navbar-nav {
		.nav-item {
			.nav-link {
				color: #ffffff;
			}
		}
	}
}

//right-language
.right-language {
	.language-btn {
		color: $white;
		font-size: 16px;
		font-weight: 700;
		& i {
			color: $white;
			font-size: 14px;
			font-weight: 300;
		}
		&::after {
			display: none;
		}
	}
	.dropdown-menu-language {
		position: absolute;
		left: -120%;
		transform: scaleY(0);
		min-width: 170px;
		padding: 10px 0;
		background: $white;
		box-shadow: 0px 25px 50px 0px rgba(213, 216, 223, 0.5);
		border: 1px solid #f8f8f8;
		border-radius: 0;
		top: 25px;
		text-align: left;
		-webkit-transform: scaleY(0);
		-moz-transform: scaleY(0);
		-ms-transform: scaleY(0);
		-o-transform: scaleY(0);
		display: none;
		@include transition(0.3s);
		li {
			display: block;
			// &:nth-child(2) {
			// 	padding-left: 0;
			// 	padding-right: 0;
			// 	margin-left: 0;
			// 	margin-right: 0;
			// }
			a {
				display: block;
				padding: 0 15px;
				font-size: 15px;
				color: $heding;
				line-height: 35px;
				text-transform: uppercase;
				&:hover {
					color: $white;
					background: $theme-color;
				}
			}
		}
	}
	.dropdown-language {
		position: relative;
		z-index: 1;
		&:hover {
			.dropdown-menu-language {
				transform: scaleY(1);
				display: inline-block;
				-webkit-transform: scaleY(1);
				-moz-transform: scaleY(1);
				-ms-transform: scaleY(1);
				-o-transform: scaleY(1);
			}
		}
	}
}

//location-dropdown
.location-dropdown {
	.dropdown-menu-language {
		left: -33%;
	}
}

//theme-menu-two
.theme-menu-two {
	position: relative;
	z-index: 3;
	.top-header {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}
	.main-header-area {
		@media (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	.navbar {
		.dropdown-menu {
			left: 0;
		}
	}
	.navbar-nav .nav-item .nav-link {
		&:last-child {
			&::before {
				display: none;
			}
		}
		@media #{$lg} {
			margin: 0 70px 0 0;
			&::before {
				display: none;
			}
		}
	}
}

//header-info
.header-info {
	li {
		&:nth-child(2) {
			.header-info-box {
				padding-right: 30px;
				padding-left: 30px;
				margin-left: 30px;
				margin-right: 30px;
				position: relative;
				z-index: 1;
				&::before {
					content: "";
					position: absolute;
					background: rgba(255, 255, 255, 0.2);
					display: inline-block;
					width: 2px;
					height: 30px;
					top: 20px;
					left: 0;
					z-index: 1;
				}
				&::after {
					content: "";
					position: absolute;
					background: rgba(255, 255, 255, 0.2);
					display: inline-block;
					width: 2px;
					height: 30px;
					top: 20px;
					right: 0;
					z-index: 1;
				}
				@media (min-width: 487px) and (max-width: 575.98px) {
					margin-left: 0;
					padding-left: 0;
					&::before {
						display: none;
					}
					&::after {
						display: none;
					}
				}
			}
		}
		&:nth-child(3) {
			.header-info-box {
				@media #{$xs} and (orientation:portrait) {
					display: none;
				}
			}
		}
	}
	.header-info-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.icon {
			color: $white;
			font-size: 30px;
			margin-right: 15px;
			margin-top: 10px;
		}
		.text-content {
			span {
				color: $white;
				font-size: 16px;
				font-family: $clash-regular;
			}
		}
	}
}

//theme-menu-three
.theme-menu-three {
	.top-header {
		position: relative;
		z-index: 1111;
	}
	.main-header-area {
		background: rgba($theme-color, 0.1);
		&.sticky-menu {
			background: $heding;
		}
		.navbar {
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}

	@media (max-width: 1199.98px) {
		.navbar-nav .nav-item .nav-link {
			margin: 0 50px 0 0;
			&::before {
				right: -27px;
			}
		}
	}
	@media (max-width: 991.98px) {
		.main-header-area {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
}
