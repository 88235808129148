//latest-case-video
.latest-case-video {
    .slick-nav {
        position: relative;
        z-index: 2;
    }
}

//video-wrapper
.video-wrapper {
    background: url(../img/video/video-1b.jpg) no-repeat;
    width: 100%;
    min-height: 650px;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 3;

    .popup-video {
        position: absolute;
        left: 50%;
        right: 0;
        top: 50%;
        z-index: 1;
        width: 66px;
        height: 66px;
        font-size: 20px;
        line-height: 66px;
        transform: translate(-50%, -50%);
    }
    @media (max-width: 991px) {
        min-height: 400px;
    }
}

//video-wrapper-two
.techy-video {
    background: url(../img/video/video-2d.jpg) no-repeat;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        background: linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, #050d36 100%);
        background: -webkit-linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, #050d36 100%);
        background: -o-linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, #050d36 100%);
        background: -ms-linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, #050d36 100%);
        background: -moz-linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, #050d36 100%);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
}
.video-wrapper-two {
    position: relative;
    z-index: 1;
    h4 {
        color: $white;
        font-size: 24px;
    }
}
