/*--
	-theme-btn
----------------------------------------*/
.theme_btn {
    overflow: hidden;
    color: $black;
    background: #fcb21d;
    font-size: 14px;
    font-family: $clash-semibold;
    font-weight: 600;
    line-height: 17px;
    padding: 17px 27px 15px 27px;
    display: inline-block;
    position: relative;
    z-index: 17px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-transform: uppercase;
    &:hover {
        color: #000;
        background: #ffffff;
        & i {
            transform: translateX(5px);
            -webkit-transform: translateX(5px);
            -moz-transform: translateX(5px);
            -ms-transform: translateX(5px);
            -o-transform: translateX(5px);
        }
    }
}
